<template>
  <div
    class="footer"
    :class="[home ? 'padHome' : '', playing ? 'foo__work' : '']"
  >
    <div class="footer__links" :class="black ? 'black' : ''">
      <router-link to="/works">WORKS</router-link>
      <router-link to="/calendar">CALENDAR</router-link>
      <router-link to="/about">ABOUT</router-link>
    </div>
    <p :class="black ? 'black' : ''">
      Copyright © 2025 valentinafurian.com. All rights reserved <br />
      Website by: 🖍️ Sofia Girelli 👨‍💻 Lorenzo Tempesti
    </p>
  </div>
</template>

<script>
export default {
  name: "Foo",
  data() {
    return {
      black: false,
      home: false,
    };
  },
  mounted() {
    const reg = new RegExp("/works/+.+$");
    if (reg.test(this.$route.path || this.$route.path === "/about"))
      this.black = true;
    if (this.$route.path === "/") this.home = true;
    else this.home = false;
  },
  watch: {
    $route() {
      const reg = new RegExp("/works/+.+$");
      if (reg.test(this.$route.path) || this.$route.path === "/about") {
        this.black = true;
      } else this.black = false;
      if (this.$route.path === "/") this.home = true;
      else this.home = false;
    },
  },
  computed: {
    playing() {
      return this.$store.state.playing;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  width: 100%;
  padding: 0 5%;
}
.footer__links {
  width: clamp(200px, 90%, 1080px);
  margin: auto;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 40px;
  /* identical to box height, or 162% */

  text-align: center;
  letter-spacing: 0.01em;
}

p {
  text-align: center;
  padding-bottom: 40px;
  color: white;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.black {
  color: var(--black);

  a {
    color: var(--black);
  }
}
.padHome {
  padding-bottom: 200vh;
  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.foo__work {
  background: var(--white);
}
</style>
